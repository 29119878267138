/* src/App.css */
.container {
    width: 80%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .card {
    width: 88%;
    padding: 15px 20px;
    background: #fffde7;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: center;
  }
  
  .text {
    width: 100%;
  }
  .title{
    margin-left:1%;
    /* font-size: 16px !important; */
    line-height: 20px !important;
    color: #0f3393;
  }
  
  /* Style the read more/less button */
  .toggle-button {
    margin-left: 7px;
    cursor: pointer;
    width: 25px;
    height: 25px;
    line-height: 25px;
    border-radius: 50%;
    font-size: 14px;
    color: #fff;
    text-align: center;
    background: #0f3393;
    font-weight: bolder;
  }
  
  /* .toggle-button:hover {
    color: red;
    text-decoration: underline;
  } */
  .readMoreHeader{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
  }
  .NewLineText{
    position:relative;
    min-width: 100%;
    color: #1070c6;
  }
  .nasvetiCardUl{
    
  }

  @media (min-width: 576px){

    
  }
  @media (min-width: 768px){

  }
  @media (min-width: 992px){
    .title{
      margin-left:0.5%;
      font-size: 16px !important;
      line-height: 20px !important;
    }
    .card {
      width: 100%;
    }
  }
  @media (min-width: 992px){

  }
  @media (min-width: 1200px){

  }
  
  @media (min-width: 1400px){

  }
  @media (min-width: 1536px){

  }