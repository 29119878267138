
  .container {
    width: 80%;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  #warehousingContentBg{
    width: 100%;
    height: auto;
    /*background-image: url("../assets/IMG_1014.jpg"); */
    background:linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url("../assets/IMG_0915.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .skladiscenjeFirstTextContainer>h1, .skladiscenjeSecondTextContainer>h1{
    text-align: center;
    padding-top:3%;
  }
  .skladiscenjeFirstTextContainer h1, .skladiscenjeSecondTextContainer h1{
    color: #0f3393;
  }
  .skladiscenjeFirstTextContainer ul, .skladiscenjeSecondTextContainer ul{
    color: #0f3393;
  }
  .skladiscenjeFirstTextContainer p, .skladiscenjeSecondTextContainer p{
    color: #1070c6;
  }

  .skladiscenjeFirstTextContainer{
    width:100%;
    min-height: 30vh;
    margin-top: 4%;

  }
  .skladiscenjeSecondTextContainer{
    min-height:60vh;
  }
  .skladiscenjeFirstTextContainer>ul{
    margin-left: auto;
    margin-right: auto;
    width: 80%;
  }
  .wareLogo{
    display: block;
    height: 60px;
    margin-top: 0.3em;
  }
  .warehousingNavbarOuterContainer{
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 576px){

    
  }
  @media (min-width: 768px){

  }
  @media (min-width: 992px){
  }
  @media (min-width: 992px){

  }
  @media (min-width: 1200px){

  }
  
  @media (min-width: 1400px){

  }
  @media (min-width: 1536px){

  }