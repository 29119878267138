.navbarContactContainer{
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}
.navbarContactContainerSub{
    display: flex;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}
.navbarContact{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.navbarContactText{
    width: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
}
.navbarContactText>h3{
    margin-bottom:0;
    font-weight:500;
    color: #0f3393 !important;
}


.navbarContactText>p{
    margin-top: 0;
    color: #0f3393 !important;
}
.navbarCallUsIcon{
    width:15px;
    height:15px;
    margin-right:4px;
}
.navbarContactImg{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.navbarSubpageLinkMenu{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: space-evenly;
    align-items: center;
    padding-right: 0px;
}
.navbarSubpageLinkMenu button{
    color:#0f3393!important;
}
.avatar{
    width: 100%!important;
    height:100%!important;
}
.navbarSubpageLinkButton{
    font-weight: 700!important;
    margin-top:0px!important;
}
.languages{
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 40px;
}
.languages>img{
    cursor: pointer;
}
.navbarSloIcon{
    width:35px;
}
.navbarEngUsIcon{
    width:35px;
}
.logoAndText{
    display:flex;
    margin-top: 5px;
}
.logoText{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
    padding-left: 10px;

}
.whiteNav{
    background-color:white!important;
}
.logoText>p{

}
.logoText>h2{
    margin-bottom:0px;
}
.OuterContainerSubpageNavbar{
    max-width: 96%!important;
    padding-left:0!important;
    padding-right:0!important;
}

.mobileLogo{
    max-height: 50px;
}

#mobileL{
    display:block;
}
#L{
    display:none;
}
#LFix{
    display:none;
}

#burgero{
    min-width: 50px;
}

.css-1q39md6-MuiButtonBase-root-MuiButton-root{
    line-height:1;
}
.abroadNavbarOuterContainer{
    justify-content: center;
    align-items: center;
    background-color:white;
}
.warehousingNavbarOuterContainer{
    background-color:white;
}

/*css magic burger menu*/
#menuToggle
{
  display: block;
  position: relative;
  /* top: 50px;
  left: 50px; */
  
  z-index: 1;
  
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a
{
  text-decoration: none;
  color: #232323;
  
  transition: color 0.3s ease;
}

#menuToggle a:hover
{
  color: #0f3393;
}


#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  -webkit-touch-callout: none;
}



/*
 * Just a quick hamburger
 */
#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  
  background: #0f3393;
  border-radius: 3px;
  
  z-index: 1;
  
  transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menuToggle span:first-child
{
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
#menuToggle input:checked ~ span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
 * But let's hide the middle one.
 */
#menuToggle input:checked ~ span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
#menuToggle input:checked ~ span:nth-last-child(2)
{
  transform: rotate(-45deg) translate(0, -1px);
}

/*
 * Make this absolute positioned
 * at the top left of the screen
 */
#menu
{
    position: absolute;
    width: 100vw;
    right: -6.5vw;
    padding-bottom: 50px;
    margin-left: 0;
    padding-left: 0px;
    padding-top: 125px;
    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    transform-origin: -9vw 100vw;
    transform: translate(+150vw, 0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    text-align: center;
}
/* #menu>a>li:hover{
    color:white;
    background:blue;
} */

#menu li
{
  padding: 10px 0;
  font-size: 22px;
}

/*
 * And let's slide it in from the left
 */
#menuToggle input:checked ~ ul
{
  transform: none;
}
#small{
    display:none;
}
#firstNavbarContact{
    margin-right:5px;
}
#extraPad, #sloToolbar, #abroadToolbar, #otherToolbar, #WarehousingToolbar{
    padding-top:8px;
    padding-bottom:8px;
}
.SloNavbarOuterContainer, .subpageNavbarOuterContainer{
    justify-content: center;
    align-items: center;
    background-color:white;
}
#smallHeaderFix{
    max-height: 80px!important;
    height:auto!important;
}
.css-11b3ww9-MuiPaper-root-MuiAppBar-root, .css-5poeop{
    height: 120px!important;
  }
  .css-11b3ww9-MuiPaper-root-MuiAppBar-root{
    background-color:white!important;
    color:#0f3393!important;
  }
  .underHeaderHeroText {
    margin-top: 120px;
}
@media (min-width: 576px){
    .navbarCallUsIcon{
        width:30px;
        height:30px;
    }
    .navbarContactText>p{
        margin-top: 0;
        color: #0f3393 !important;
    }
    .navbarContactText>h3{
        margin-bottom:0;
        color: #0f3393 !important;
    }
    .mobileLogo{
        max-height: 40px;
    }
    .css-11b3ww9-MuiPaper-root-MuiAppBar-root, .css-5poeop{
        height: 90px!important;
      }
    .underHeaderHeroText {
        margin-top: 90px;
    }
    #menu ul{
        margin: +18px 0 0 -305px;
        text-align: center!important;
    }
    .underHeaderHeroTextAbroad, .underHeaderHeroTextSlo, .underHeaderHeroTextOther, .underHeaderHeroTextWarehousing{
        margin-top: 90px;
      }
  }
  @media (min-width: 768px){
    .navbarSubpageLinkMenu{
        padding-right: 20px;
    }
    .mobileLogo{
        max-height: 60px;
    }
    .navbarContactContainer{
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: space-evenly;
        align-items: center;
        padding-right: 20px;
        font-size: 0.8rem;
    }
    .navbarContact{
        margin-right: 10px;
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    .css-11b3ww9-MuiPaper-root-MuiAppBar-root, .css-5poeop{
        height: 100px!important;
      }
    .underHeaderHeroText {
        margin-top: 100px;
    }
    .underHeaderHeroTextAbroad, .underHeaderHeroTextSlo, .underHeaderHeroTextOther, .underHeaderHeroTextWarehousing{
        margin-top: 100px;
      }
    #menu{
        margin: +18px 0 0 -305px;
        width: 450px;
        padding-left: 20px;
        text-align: initial;
    }

  }
  @media (min-width: 992px){
    .mobileLogo{
        max-height: 70px;
    }
    .navbarContactText>p {
        min-width: 125px;
        color: #0f3393 !important;
    }
    #mobileL{
        display:none;
    }
    #L{
        display:block;
        max-height: 100px;
        height:100px;
    }
    #LFix{
        display:block;
        max-height: 100px;
        height:66px!important;
        width:210px!important;
    }
    .css-11b3ww9-MuiPaper-root-MuiAppBar-root, .css-5poeop{
        height: 120px!important;
      }
    .underHeaderHeroText {
        margin-top: 120px;
    }

    .OuterContainerSubpageNavbar{
        max-width: 90%!important;
        padding-left:0!important;
        padding-right:0!important;
    }
    .underHeaderHeroTextAbroad, .underHeaderHeroTextSlo, .underHeaderHeroTextOther, .underHeaderHeroTextWarehousing{
        margin-top: 120px;
      }
  }
  @media (min-width: 1200px){
    .mobileLogo{
        max-height: 80px;
    }
  }
  
  @media (min-width: 1400px){
    .navbarSubpageLinkButton{
        font-size: 1.5rem!important;
        font-weight: 700!important;
        margin-top:0px!important;
    }
    .css-11b3ww9-MuiPaper-root-MuiAppBar-root, .css-5poeop{
        height: 150px!important;
      }
    .underHeaderHeroText {
        margin-top: 148px;
    }
    #menu{
        margin: +18px 0 0 -305px;
    }
    .underHeaderHeroTextAbroad, .underHeaderHeroTextSlo, .underHeaderHeroTextOther, .underHeaderHeroTextWarehousing{
        margin-top: 148px;
      }
  }
  @media (min-width: 1536px){

  }