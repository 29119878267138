.privacyFirstTextContainer>h1, .privacyFirstTextContainer>h1{
    text-align: center;
    padding-top:3%;
  }
  .privacyFirstTextContainer h1, .privacyFirstTextContainer h1{
    color: #0f3393;
  }
  .privacyFirstTextContainer h2, .privacyFirstTextContainer h2{
    color: #0f3393;
  }
  .privacyFirstTextContainer p, .privacyFirstTextContainer p{
    color: black;
  }
  #privacyFirstTextContainerLast{
    margin-bottom:4%;
  }
  .privacyFirstTextContainer{
    width: 90%;
    min-height: 30vh;
    margin-top: 4%;
    margin-bottom: 4%;
    padding-bottom: 1%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .privacyTextContainer{
    margin-left:1%;
    margin-right:1%;
  }
  .ResponsibilityContainer{
    width: 100%;
    min-height: 30vh;
    margin-top: 4%;
    margin-bottom: 4%;
    padding-bottom: 1%;
  }
  .ResponsibilityTextContainer {
    margin-left: 1%;
    margin-right: 1%;
}
.ResponsibilityContentInnerContainer{
  width: 90%;
  background: #ffffffd6;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vh;
  height: auto;
  padding-bottom: 1%;
  margin-top: 150px;
}
.responsibilityButton{
  display: inline-block;
  padding: 5px 30px;
  border-radius: 50px;
  border: 2px solid #0f3393;
  color: #0f3393;
  background-color: #fff;
  text-decoration: none !important;
  font-weight: bold;
  cursor: pointer;
}
.responsibilityButton:hover{
  background-color: #0f3393;
  color: #fff;
}
.centerButtonParent{
  margin-top:20px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}