.otherFirstTextContainer{

}
.otherFirstTextContainer>ul{
    text-align: left;
    margin-right: auto;
    text-transform: uppercase;
    font-weight: 600;
}
.firstTextContainers{
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 80%;
    min-height: 30vh;
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.firstTextContainers h1{
    color: #0f3393;
}
.firstTextContainers ul{
    color: #0f3393;
}
.firstTextContainers p{
    color: #1070c6;
}
.otherContentInnerContainer{
    width: 90%;
    background: #ffffffd6;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5vh;
}
#otherContentBg{
    width: 100%;
    height: auto;
    /*background-image: url("../assets/IMG_1014.jpg"); */
    background:linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url("../assets/IMG_0895.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }