.abroadContentContainer{
    width: 100%;
    height: auto;
    /*background-image: url("../assets/IMG_1014.jpg"); */
    background:linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url("../assets/IMG_0991.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto;
}
.abroadInnerContainer{
    width: 90%;
    background: #ffffffd6;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5vh;
  }
  .blueTitle{
    color: #0f3393;
  }
  .abroadInnerContainer h2{

  }
  @media (max-width: 992px){
    .abroadInnerContainer h2{
        font-size: 1.2rem;
    }
  }