/* General table styling */
.furniture-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-family: Arial, sans-serif;
  }
  
  /* Header styling */
  .furniture-table th {
    background-color: #f4f4f4;
    font-weight: bold;
    padding: 10px;
    border-bottom: 2px solid #ddd;
    text-align: left;
  }
  
  /* Row styling */
  .furniture-table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  /* Quantity control container */
  .quantity-control {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }
  
  /* Button styling */
  .quantity-btn {
    padding: 5px 10px;
    border: none;
    background-color: #004b87; /* Dark blue background for buttons */
    color: white;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .quantity-btn.minus {
    background-color: #d9534f; /* Red background for minus button */
  }
  
  .quantity-btn.plus {
    background-color: #004b87; /* Blue background for plus button */
  }
  
  .quantity-btn:hover {
    background-color: #003a65; /* Slightly darker blue on hover */
  }
  
  .quantity-btn.minus:hover {
    background-color: #c9302c; /* Slightly darker red on hover */
  }
  
  /* Input styling */
  .quantity-input {
    width: 40px;
    text-align: center;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Modal styling to match the design */
  .modal {
    max-width: 95%;
    max-height:90vh;
    margin: auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: auto;
    margin-top: 25vh;
    margin-bottom: 15vh;
  }
  
  /* Modal header */
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  /* Confirm button at the bottom */
  .summary {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  
  .summary button {
    padding: 10px 20px;
    font-size: 14px;
    color: #fff;
    background-color: #004b87;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .summary button:hover {
    background-color: #003a65;
  }
  
  /* Tabs styling */
  .tabs {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    max-width: 100%;
    overflow-x: scroll;
  }
  
  .tabs button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background-color: #f4f4f4;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s;
  }
  
  .tabs button.active-tab {
    background-color: #004b87;
    color: white;
  }
  
  .tabs button:hover {
    background-color: #ddd;
  }
  