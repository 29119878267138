.App {
  
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.firstLink{
  width: 100%;
  margin-bottom: 10%;
}
.heroImgPc{
  display:none;
}
.heroImgPhone{
  display:initial;
}
.removeBold{
  font-weight: 500;
}
.selectContainer{
  display:flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width:100%;
}
.App-header {
  background-color: #1070c6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
a{
  color: #1070c6;
  text-decoration: none;
  font-weight: bold;
}
a:hover{
  color: #1070c6;
  text-decoration: none;
}
.App-link {
  color: #1070c6;
}
#notAllowedCursor{
  cursor: not-allowed;
}
.heroPage{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  min-height: 80vh;
}
.underHeaderHeroTextAbroad, .underHeaderHeroTextSlo, .underHeaderHeroTextOther, .underHeaderHeroTextWarehousing{
  width:100%;
  background: #0f3393;
  margin-left:auto;
  margin-right:auto;
  margin-top: 120px;
}
.underHeaderHeroTextAbroad> h3, .underHeaderHeroTextSlo>h3, .underHeaderHeroTextOther>h3,.underHeaderHeroTextWarehousing>h3{
  margin-left:auto;
  margin-right:auto;
  width:98%;
  text-align: left;
  color:white;
  font-weight: 500;
}
.OfficeLocationLink{
  color:black;
}

.underHeaderHeroText{
  width:100%;
  background: #0f3393;
  margin-left:auto;
  margin-right:auto;
  margin-top: 10vh;
}

.underHeaderHeroText> h3{
  margin-left:auto;
  margin-right:auto;
  width:98%;
  text-align: left;
  color:white;
  font-weight: 500;
}
.serviceContainer{
  width: 100%;
  height: auto;
  min-height: 80vh;
}
.servicesCenteredContainer{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-evenly;
  align-items: flex-start;
  margin-top: -52px;
}
#nonClickable1, #nonClickable2{
  cursor: not-allowed!important;
}
.service{
  color:white;
  /*background: blue;*/
  height: 42vh;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
}
#service1{
  background:linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url("../assets/bg/first.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#service2{
  background:linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url("../assets/bg/second.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#service3{
  background:linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url("../assets/bg/third.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#service4{
  background:linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url("../assets/bg/fourth.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#service5{
  background:linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url("../assets/bg/fifth.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#service6{
  background:linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url("../assets/bg/sixth.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#service7{
  background:linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url("../assets/bg/seventh.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.mapContainer>iframe{
  min-height: 78vh;
}
.serviceTitle{
  background: #0f3393;
  width: 100%;
  text-align: center;
  height: 10%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.serviceImg{
  height: 80%;
  width: 100%;
}
.serviceUnderImageText{
  width: 100%;
  height: 10%;
  display: flex;
  background-color:white;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.serviceTitle>h3{
  margin-top: 0;
  margin-bottom: 0;
  font-weight:500;
}
.sendInquiry{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
  padding-top: 3%;
}
.sendInquiryInnerContainer{
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-end;
  width: auto;
  padding-top: 4%;
  padding-bottom: 4%;
}
.inquiryImage{

}
.inquiryTitle{
  width: 100%;
  background: #0f3393;
  color: white;
  border-top: solid black 4px;
}
.inquiryTitle>h3{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}
.contactTitle{
  width: 100%;
  background: #0f3393;
  color: white;
  border-top: solid black 4px;
  text-align: left;
  display:flex;
}
.contactTitle>h3{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
}
.inquiryText{
  width: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  color:#0f3393;
}
.inquryIcon{
  width:80px;
}
.contactIcon{
  width:40px;
}
.inquiryIconCont{
  padding-right: 10px;
}
.inquryParagraph{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
}
.inquryParagraph>h2, .inquryParagraph>p{
  margin-top:0px;
  margin-bottom:5px;
}
.heroImage{
  width:100%;
  height:auto;
}
.HeroImg{
  width:100%;
  height:auto;
}
.logo{
  height:100px;
}
.servicesCenteredBottomContainer{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.servicesSecondContainer{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}
.secondServicesTitle{
  width: 100%;
  text-align: center;
  margin-top: 4%;
  margin-bottom: 4%;
}
.inquiryPage{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.inquiryPageInnerContainer{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;

   background:linear-gradient(0deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), url("../assets/IMG_1047.webp");
  background-position: center;
  background-position-y: 5%;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}
.inquiryFormContainer{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: #1071c65e;
  min-height: 100vh;
  margin-top: auto;
  margin-bottom: auto;
  color:white;
}
.radioButtons{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  margin-top: 4%;
  margin-bottom: 4%;
  font-size: 1rem;
  font-weight: 500;
  color: white;
}
.radioButtonsLeft, .radioButtonsRight{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
}
.radioButtonsLeft > span, .radioButtonsRight > span{
  padding-bottom:10px;
}
.inquiryFormInnerContainer{
  width: 100%;
  margin-bottom: auto;
}
.lowerHalfOfForm{
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: flex-start;
  margin-top:1%;
  overflow-x: hidden;
}
.leftForm{
  width:100%;
}
.rightForm{
  width:100%;
}
.rightForm>textarea{
  max-width:100%;
  width: 100%;
}
#quickfixCalc input{
  width: 40px;
  height:auto;
  text-align: center;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom:0px;
}
.calculator-container{
  width:100%;
}
.leftForm input{
    height: 40px;
    width: 99%;
    margin-bottom:1%;
}
#selimIz, #selimV{
    width: 50%;
    height: 80px;
}
.inLineInput{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: center;
}
.formDropdown{
    height: 40px;
    width: 100%;
    margin-bottom: 1%;
    color: rgb(118, 118, 118);
}
.submits{
  display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 2.5%;
    color:white;
    padding-bottom:5%;
}
#firstSubmit{
  width: 200px;
  height: 60px;
  border-radius: 15px;
  background: #0f3393;
  font-weight: 900;
  cursor:pointer;
  color:white;
}
#secondSubmit{
  width: 250px;
  height: 60px;
  border-radius: 15px;
  background: #0f3393;
  font-weight: 900;
  cursor: pointer;
  text-align: center;
}
.inquiryFormTitle{
  margin-top: 3%;
  color:white;
}
.radioButtons input{
  transform: scale(2);
}
.aboutUsContainer{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.aboutUsInnerContainer{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
  align-items: center;
  width: 90%;
  height: 80vh;
}
.aboutUsCategory{
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1%;
  background:grey;
  text:white;
}
.categoryTitle{

}
.categoryText{

}
.categoryButton{
  background: #0f3393;
  width: 160px;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 1%;
  font-weight: 700;
  color: white;
}
.mapContainer{
  height: 80vh;
  width: 100%;
  overflow: hidden;
}   
.aboveMapContainer{
  padding-top:2%;
  padding-bottom:2%;
}
.aboveContactText{
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
} 
.aboveContactText h2{
  margin-bottom:0;
}
.aboveContactText h3{
  margin-top:0;
  font-weight: 300;
}
.aboveContactTextIcon{
 padding-right:10px; 
}
.bellowContactTextIcon{
  padding-right:10px; 
}
.bellowContactText{
  display: flex;
  flex-wrap: nowrap;
  align-content: flex-start;
  justify-content: flex-start;
  align-items: center;
}
.bellowContactTextText>h2{
  margin-bottom:0px;
}
.bellowContactTextText>h3{
  margin-top:0px;
  font-weight: 300;
}
.centeredAboveMapContainer{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}
.centeredBellowMapContainer{
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
}

/* modal */
.container {
  width: 60%;
  margin: 0 auto;
  font-family: "Hammersmith One", sans-serif;
}

.header {
  text-align: center;
  margin: 20px 0;
}

.main-form {
  text-align: center;
  margin: 20px 0;
}

.furniture-table,
.summary-table {
  width: 100%;
  border-collapse: collapse;
  min-height: 200px;
}

.furniture-table th,
.summary-table th,
.furniture-table td,
.summary-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  color:black;
  vertical-align: middle;
  width:50%;
}
.furniture-table td {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.furniture-table td .quantity-control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px; /* Adds some space between item name and controls */
}
.summary {
  text-align: center;
  margin-top: 20px;
}

.total {
  margin-top: 20px;
  font-weight: bold;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}



.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}
.modal-content {
  min-height: 300px; /* Adjust this value based on your content */
  transition: min-height 0.3s ease-in-out; /* Optional smooth transition */
}

/* Inventory button styling */
.inventory-button {
  background-color: #003366;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 99%;
  margin-bottom: 20px;
}
/* Selected items list */
.selected-items {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
  width: 94%;
}



.modal {
  width: 100%;
  padding: 15px;
  background: white;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: scroll;
}

.modal-header h2 {
  font-size: 18px;
}

.tabs button {
  font-size: 14px;
  padding: 8px;
}

.summary button {
  font-size: 12px;
  padding: 6px 12px;
}

.quantity-control input {
  width: 35px;
}
.quantity-btn {
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
}

.quantity-btn:hover {
  background-color: #0055cc;
}

.quantity-btn.minus {
  background-color: #cc0000;
}

.quantity-btn.minus:hover {
  background-color: #ff4444;
}
.submit-button {
  background-color: #003366;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
}
.selectionModalHeader{
  display:none;
}
/* modal end*/

@media only screen and (max-width: 768px) {
  .furniture-table td{
    width:94%;
  }
  .furniture-table, .summary-table{
    min-width:84%;
  }
  .tabs button {
    font-size: 14px;
  }
  .modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    margin-top: 10%;
    width: 80%;
    height: 70vh;
    min-height: 60vh;
  }
  .MuiMenu-paper{
    top: 25% !important;
    height: 40%;
    width: 88%;
    left: 6% !important;
    right: 6%;
    background-color: #0f3393 !important;
    color: white!important;
  }
  .MuiTypography-root>a{
    color: white !important;
    font-size: 2rem;
  }
  .MuiList-root>li:hover{
    background:#1070c6;
  }
  .MuiBackdrop-root{
    background-color:rgba(128, 128, 128, 0.781)!important;
  }
  .MuiTypography-root{
    margin-left: auto!important;
    margin-right: auto!important;
    font-size: 2rem!important;
  }
  .MuiButtonBase-root .MuiMenuItem-root .MuiMenuItem-gutters .MuiMenuItem-root .MuiMenuItem-guttersMuiMenuItem-root{
    background-color:rgba(255, 255, 255, 0.363)!important;
  }
}

@media (min-width: 576px){
  .firstLink{
    width: 100%;
    margin-bottom: initial;
  }
  .inventory-button{
    width:100%;
  }
  .selected-items {
    width: 88%;
}
.quantity-control button {
  padding: 4px 8px;
  font-size: 16px;
}
}
@media (min-width: 768px){
  .selectionModalHeader{
    display: table-cell;
  }
  .furniture-table td {
    display: table-cell;
  }
  .furniture-table, .summary-table{
    min-width:84%;
  }
  .modal {
    width: 80%;
    max-width: 90%; /* Ensure that the modal doesn't exceed the screen width */
  }

  .modal-header h2 {
    font-size: 18px;
  }
  .heroPage{
    margin-top: initial
  }


  .summary button {
    font-size: 14px;
    padding: 8px 16px;
  }

  .quantity-control input {
    width: 40px;
  }

  .quantity-control button {
    padding: 5px;
  }
  .inventory-button{
    width:100%;
  }
  .selected-items {
    width: 88%;
}
  .selectContainer{
    width:97%;
    /* align-content: flex-start; */
  }
  .formDropdown{
    width:99%;
  }
  .firstLink{
    width: 100%;
  }
  .leftForm{
    width:49%;
  }
  .rightForm{
    width:49%;
  }
  .inquiryFormInnerContainer{
    width: 60%;
    margin-bottom: auto;
  }
  .leftForm Input{
    width:95%;
  }
  #selimIz, #selimV{
    width: 48%;
    height: 80px;
}
.inLineInput{
  width:97%;
}
.sendInquiryInnerContainer{
  padding-top: initial;
  padding-bottom: initial;
}
.secondServicesTitle{
  margin-top: 1%;
  margin-bottom: 1%;
}
.radioButtonsLeft > span, .radioButtonsRight > span{
  padding-bottom:4px;
}
.inventory-button{
  width:100%;
}
.selected-items {
  width: 88%;
}
}
@media (min-width: 992px){
  .inventory-button{
    width:100%;
  }
  .selected-items {
    width: 88%;
}
  .heroImgPc{
    display:initial;
  }
  .heroImgPhone{
    display:none;
  }
  .firstLink{
    width: 50%;
  }
  .radioButtons{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 4%;
    margin-bottom: 4%;
    font-size: xx-large;
    font-weight: 500;
    color: white;
  }
  .firstLink{
    width: 40%;
  }
}
@media (min-width: 1200px){
  .inventory-button{
    width:99%;
  }
  .firstLink{
    width: 22%;
  }
}

@media (min-width: 1400px){
  .firstLink{
    width: 22%;
  }
}
@media (min-width: 1536px){
  .css-19r6kue-MuiContainer-root {
    max-width: 90%!important;
}
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
