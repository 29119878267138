#footer{
    background: #0f3393;
    min-height: 4vh;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    color:white;
    font-weight: 500;
}
#footerFirst{
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}
#madeBy{
    width: 100%;
    height: 30px;
    background-color: #1a233f;
    color: #fff;
    text-align: center;
    font-family: "Titillium Web", sans-serif;
    align-content: center;
    cursor:pointer;
}
#footerFirst>a{
    text-decoration: none;
    color:white;
    cursor: pointer;
    font-weight: 500;
}
#footerSecond>a{
    text-decoration: none;
    color:white;
    cursor: pointer;
    font-weight: 500;
}
#footerSecond{
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
}

@media (min-width: 576px){

  
}
@media (min-width: 768px){
    #footerSecond{
        max-width: 50%;
        width: 45%;
    }
    #footerFirst{
        max-width: 50%;
        width: 45%;
    }
}
@media (min-width: 992px){

}
@media (min-width: 992px){

}
@media (min-width: 1200px){

}

@media (min-width: 1400px){

}
@media (min-width: 1536px){

}