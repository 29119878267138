.inquiryPage{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    width:100%;
    height:auto;
}
.inquiryFormContainerSlo{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background: #1071c65e;
    min-height: 100vh;
    height:auto;
}
.radioButtonsSlo{
  display:none;
}
.submits{
  display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin-top: 2.5%;
    color:white;
}
#firstSubmit{
  width: 200px;
  height: 60px;
  border-radius: 15px;
  background: darkblue;
  font-weight: 900;
  cursor:pointer;
  color:white;
}
#secondSubmit{
  width: 250px;
  height: 60px;
  border-radius: 15px;
  background: #0f3393;
  font-weight: 900;
  cursor: pointer;
  text-align: center;
}
.radioButtons input{
  transform: scale(2);
}
.pageText{
    text-align: center;
    width:100%;
    min-height: 6vh;
    margin-top: 4%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
}
.pageText p{
  color: #1070c6;
  font-size:16px;
}
.pageText h1{
  color: #0f3393;
}
.SloContentInnerContainer{
  width: 90%;
  background: #ffffffd6;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vh;
}
.contentContainer{
  width: 100%;
  height: auto;
  /*background-image: url("../assets/IMG_1014.jpg"); */
  background:linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url("../assets/IMG_1014.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#sloContentBg{

}

@media (min-width: 576px){

  
}
@media (min-width: 768px){

}
@media (min-width: 992px){

}
@media (min-width: 992px){

}
@media (min-width: 1200px){

}

@media (min-width: 1400px){

}
@media (min-width: 1536px){

}