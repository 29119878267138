html { 
  scroll-behavior: smooth;
  overflow-x:hidden;
  scroll-padding-top:80px;
}
body {
  margin: 0;
  padding-right: 0!important;
  font-family: "Hammersmith One", sans-serif;
  font-style: normal;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow:hidden;
}
input, textarea, select { font-family:inherit; }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1{
  text-transform: uppercase;
}
h1,h2,h3,h4,p,a, ul, span{
  font-size: 0.7rem;
}

@media (min-width: 576px){

}
@media (min-width: 768px){
  h1,h2,h3,h4,p,a, ul, span{
    font-size: 0.8rem;
  }
}
@media (min-width: 992px){
  h1,h2,h3,h4,p,a{
    font-size: revert;
  }
}
@media (min-width: 992px){

}
@media (min-width: 1200px){

}

@media (min-width: 1400px){

}
@media (min-width: 1536px){

}